<template>
  <DashboardTemplate>
    <Layout class="pd-y-3">
      <FormData
        :edit="false"
        :routerBack="`/system/admin`"
        :ruleForm="ruleForm"
        :validate="true"
        @emitData="saveData"
      />
    </Layout>
  </DashboardTemplate>
</template>
<script>
import Layout from "@/template/LayoutDashboardTemplate";
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import FormData from "@/components/formData/FormAdminData";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    FormData,
    Layout
  },
  data() {
    return {
      sPhrase: '',
      ruleForm: {
        titleName: null,
        name: "",
        surname: "",
        email: "",
        role: null,
        gender: 0,
        age: null,
        education: null,
        mobilephone: "",
        password: ""
      }
    };
  },
  mounted(){
    this.getSphase();
  },
  methods: {
    async getSphase(){
      try {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.$store.state.sphrase}`;
        let res = await HTTP.get(`key/sign`)
        if(res.data.success){
          this.sPhrase = res.data.data
        }
      } catch (error) {
        
      }
    },
    saveData(data) {
      console.log("saveData", data);
      let tempPassword = this.CryptoJS.AES.encrypt(
        data.password,
        this.sPhrase
      ).toString();
      let obj = {
        titleName: data.titleName,
        name: data.name,
        surname: data.surname,
        email: data.email,
        role: data.role,
        gender: data.gender,
        age: data.age,
        education: data.education,
        mobilephone: data.mobilephone,
        password: tempPassword
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`system/admin/emp`, obj)
        .then(res => {
          if (res.data.success) {
            this.$message({
              showClose: true,
              message: "เพิ่มข้อมูลสำเร็จ",
              type: "success"
            });
            this.$router.push("/system/admin");
          } else {
            this.alertCatchError(res.data.data);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    }
  }
};
</script>